export const ar = {
  // +++++++++++++++++
  //      SIDEBAR
  // +++++++++++++++++
  Home: "الرئيسية",
  Orders: "الطلبات",
  Users: "المستخدمون",
  "User requests": "طلبات المستخدمين",
  Banner: "اللافتة",
  Notification: "الإشعارات",
  Staff: "الموظفين",
  Setting: "الإعدادات",
  Logout: "تسجيل الخروج",

  // +++++++++++++++++
  //    DASHBOARD
  // +++++++++++++++++
  Dashboard: "لوحة التحكم",
  Merchant: "مستخدم",
  Delivery: "التوصيل",
  Income: "الدخل",
  "Delivery Price": "سعر التسليم",
  "App comission": "عمولة التطبيق",
  "Total Price": "السعر الإجمالي",
  "User type": "User type",
  "View rider": "View rider",


  // +++++++++++++++++
  //    ORDERS
  // +++++++++++++++++
  "Returned Orders": "الطلبات المُرتجعة",
  "Order Images": "صور الطلب",
  "Order status": "حالة الطلب",
  "Completed Orders": "الطلبات المكتملة",
  "Underway Orders": "الطلبات الجارية",
  "Delivered Order": "الطلبات المسلّمة",
  "Order ID": "معرف الطلب",
  "Order Type": "نوع الطلب",
  "Order details": "تفاصيل الطلب",
  "Date Order": "تاريخ الطلب",
  "Underway Order": "الطلب الجاري",
  "Complete Order": "الطلب المكتمل",
  "Returned Order": "الطلب المُرتجع",

  // ++++++++++++++++++++
  //       USERS
  // ++++++++++++++++++++
  "Delivery Boys": "فتيان التوصيل",
  Merchants: "التجار",
  "Merchant Name": "اسم التاجر",
  "Customer Name": "اسم العميل",
  "Delivery Boy": "فتى التوصيل",
  "User name": "اسم المستخدم",

  // +++++++++++++++++++++++
  //       USER REQUESTS
  // +++++++++++++++++++++++
  "Rejected Reason": "سبب الرفض",
  "Enter rejected reason": "أدخل سبب الرفض",

  // +++++++++++++++
  //     BANNER
  // +++++++++++++++
  "Enter post url": "أدخل رابط المنشور",
  "Edit Banner": "تعديل اللافتة",
  "Add Banner": "إضافة لافتة",
  url: "الرابط",
  "Start date": "تاريخ البدء",
  "End date": "تاريخ الانتهاء",

  // +++++++++++++++++++
  //   NOTIFICATION
  // +++++++++++++++++++
  "Send Notification": "إرسال إشعار",
  "User phone": "هاتف المستخدم",
  Title: "العنوان",
  Description: "الوصف",
  "Enter the Description here": "أدخل الوصف هنا",

  // +++++++++++++++++++++
  //        STAFF
  // +++++++++++++++++++++
  "Add Staff": "إضافة موظف",
  Type: "النوع",

  // +++++++++++++++++++++++++
  //          SETTINGS
  // +++++++++++++++++++++++++++
  "Terms & Privacy Settings": "إعدادات الشروط والخصوصية",
  "Company Settings": "إعدادات الشركة",
  "Company Title": "عنوان الشركة",
  "Support Name": "اسم الدعم",
  Mobile: "الهاتف المحمول",
  "Country Code": "رمز الدولة",
  "Support Email": "البريد الإلكتروني للدعم",
  "Company Whatsapp": "واتساب الشركة",
  "Company X": "شركة X",
  "Company Instagram": "إنستغرام الشركة",
  Referral_bonus: "مكافأة الإحالة",
  "Login Image": "صورة تسجيل الدخول",

  // +++++++++++++++++++++++
  //       SERVICES
  // +++++++++++++++++++++++
  "Price Detail": "تفاصيل السعر",

  // ++++++++++++++++++++++++++++
  //       LOCATION
  // ++++++++++++++++++++++++++++
  "Customer Location": "موقع العميل",
  "Merchant location": "موقع التاجر",

  // ++++++++++++++++++++++++++++
  //       INFORMATION
  // ++++++++++++++++++++++++++++
  "Rider Information": "معلومات السائق",

  // +++++++++++++++++
  //      GLOBAL
  // +++++++++++++++++
  "Amount added": "Amount added",
  "Enter Title": "أدخل العنوان",
  "Enter amount": "أدخل المبلغ",
  "Are you sure you want to delete the record ?":
    "هل أنت متأكد أنك تريد حذف السجل؟",
  Cancel: "إلغاء",
  OK: "موافق",
  Edit: "تعديل",
  Yes: "نعم",
  Amount: "المبلغ",
  "Referral Code": "رمز الإحالة",
  "Completed Orders": "الطلبات المكتملة",
  "Add Balance": "إضافة رصيد",
  Reject: "رفض",
  Accept: "قبول",
  Note: "ملاحظة",
  Username: "اسم المستخدم",
  Time: "الوقت",
  View: "عرض",
  Date: "التاريخ",
  From: "من",
  To: "إلى",
  Status: "الحالة",
  Action: "الإجراء",
  Image: "الصورة",
  Name: "الاسم",
  Email: "البريد الإلكتروني",
  Id: "الرمز",
  Phone: "الهاتف",
  Add: "إضافة",
  Delete: "حذف",
  "Created at": "تم الإنشاء في",
  Save: "حفظ",
  Password: "كلمة المرور",
  No: "لا",
  Post: "بريد",
  "OMR": "﷼",

  "Balance requests": "طلبات الرصيد",
  "Merchant info": "معلومات التاجر",
  "Rider Info" : "معلومات الراكب"
};
