import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography, Image } from "antd";

import Layout from "../../components/Layout";
import {
    DotMenuIcon,
    TrashIcon,
    EyeIcon2,
    EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import BannerModel from "../../components/BannerModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";

import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import AppLoader from "../../components/Loader/AppLoader";
import Modal from "../../components/Modal";
import AddPostModel from "../../components/AddPostModal";
import { BASE_URL, IMAGE_URL } from "../../Api/constants";
import { getRequest, postRequest } from "../../Api";
import { routes } from "../../Api/routes";

import DataTable from "../../components/DataTable";
import EditBanner from "../../components/EditBanner";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";
import RejectedReasonModel from "../../components/RejectedReasonModel";

const BalanceRequests = () => {

    const words = useSelector((state) => state.authReducer.words);
    const navigate = useNavigate();

    const [deletedId, setDeletedId] = useState("");
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(false);

    const [balanceRequests, setBalanceRequests] = useState([]);
    const [balanceRequestId, setBalanceRequestId] = useState('');
    const [rejectedBalanceRequestModel, setRejectedBalanceRequestModel] = useState(false);


    const columns = [
        {
            title: words["Id"],
            dataIndex: "id",
            key: "title",
        },
        {
            title: words["Amount"],
            dataIndex: "amount_requested",
            key: "amount_requested",
            render: (_, record) => (
                <div
                    className="px-2 py-2 text-left text-sm text-black font-medium"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {words["OMR"]} {record.amount_requested}
                </div>
            )
        },
        {
            title: words["Name"],
            dataIndex: "ridername",
            key: "rider.name",
            render: (_, record) => (
                <div
                    className="px-2 py-2 text-left text-sm text-black font-medium"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {capitalizeFirstLetter(record.rider?.username)}
                </div>
                // user-details/7
            )
        },


        {
            title: words["Date"],
            dataIndex: "date",
            key: "date",
            render: (_, record) => (
                <div
                    className="px-2 py-2 text-left text-sm text-black font-medium"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                >
                    {getDateString(record.created_at)}
                </div>
            ),
        },



        {
            title: words["Action"],
            key: "action",
            render: (_, record) => (
                <Dropdown
                    overlay={
                        <Menu
                            onClick={({ key }) => {
                                if (key == "view") {
                                    navigate("/dashboard/user-details/" + record.rider.id);
                                    return;
                                }

                                setBalanceRequestId(record.id);
                                if (key == 'add') {
                                    return handleBalanceRequest('added', null, record.id)
                                }
                                if (key == 'reject') {
                                    setRejectedBalanceRequestModel(true);
                                    return;
                                }
                            }}
                        >
                            <>
                                <Menu.Item key="add">{words["Add"]}</Menu.Item>
                                <Menu.Item key="reject">{words["Reject"]}</Menu.Item>
                                <Menu.Item key="view">{words["View rider"]}</Menu.Item>
                            </>
                        </Menu>
                    }
                    trigger={["click"]}
                >
                    <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
                        <DotMenuIcon />
                    </div>
                </Dropdown>
            ),
        },
    ];


    const handleBalanceRequest = async (status, reason, id) => {

        setRejectedBalanceRequestModel(false);
        const onSuccess = () => {
            if (status == 'added') toast.success('Balance added successfully');
            if (status == 'rejected') toast.success('Balance request rejected successfully');
            getBalanceRequests();
        }

        const onError = (res) => {
            setLoading(false);
            toast.error(res.error)
        }

        const body = {
            status,
            id: id
        }
        if (status == 'rejected') body.rejected_reason = reason;

        setLoading(true);
        await postRequest(body, 'admin/rider/balance-request', true, onSuccess, onError)
    }



    const getBalanceRequests = async () => {
        const onSuccess = (res) => {
            setBalanceRequests(res.data);
            setLoading(false);
        };

        const onError = (err) => {
            setLoading(false);
            toast.error(err?.error);
        }

        setLoading(true);
        await getRequest("", 'admin/rider/balance-request', true, onSuccess, onError);
    };



    useEffect(() => {
        getBalanceRequests();
    }, []);


    return (
        <>
            {loading && <Loader />}
            <Layout pageTitle={words["Banner"]}>
                <div className="banner-page min-h-screen flex">
                    <div className="wrap wrapWidth flex flex-col">
                        <div className="flex flex-col gap-5 mt-6">


                            <div>
                                <DataTable
                                    searchContainerStyle={{ width: "40%" }}
                                    data={balanceRequests}
                                    columns={columns}
                                />
                            </div>
                        </div>
                    </div>

                </div>

                <Modal open={rejectedBalanceRequestModel} setOpen={setRejectedBalanceRequestModel}>
                    <RejectedReasonModel
                        openModal={rejectedBalanceRequestModel}
                        setOpenModal={setRejectedBalanceRequestModel}
                        onSubmit={(reason) => handleBalanceRequest('rejected', reason, balanceRequestId)}
                    />
                </Modal>


            </Layout>
        </>
    );
};

export default BalanceRequests;
