import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Dropdown, Menu, Button, Typography, Image } from "antd";

import Layout from "../../components/Layout";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../assets/Icons";
import SearchBar from "../../components/SearchBar";
import Modal from "../../components/Modal";
import AddStaffModel from "../../components/AddStaffModel";
import DeleteConfirmationModel from "../../components/DeleteConfirmationModel";
import { capitalizeFirstLetter, getDateString } from "../../utils/functions";
import {
  useDeleteStaffMutation,
  useGetStaffQuery,
} from "../../store/services/staffService";
import AppLoader from "../../components/Loader/AppLoader";
import DataTable from "../../components/DataTable";
import { useSelector } from "react-redux";
import { IMAGE_URL } from "../../Api/constants";

const Staff = () => {
  const words = useSelector((state) => state.authReducer.words);
  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deletedId, setDeletedId] = useState("");
  const { data: staff, isLoading: staffLoading } = useGetStaffQuery();
  const [deleteStaff, { isLoading: deleteStaffLoading, isSuccess, isError }] =
    useDeleteStaffMutation();

  const columns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Image"],
      dataIndex: "image",
      key: "image",
      render: (_, record) => (
        <div className="flex items-center">
          <Image
            style={{ width: 60, height: 60 }}
            src={IMAGE_URL + record.image}
            // alt={record.image}
            className="h-14 w-24 object-cover bg-gray-300 rounded-lg"
          />
        </div>
      ),
    },
    {
      title: words["Phone"],
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: words["Email"],
      dataIndex: "email",
      key: "email",
    },
    {
      title: words["Type"],
      dataIndex: "type",
      key: "type",
    },
    {
      title: words["Created at"],
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}
        </div>
      ),
    },
    {
      title: words["Action"],
      key: "action",
      render: (_, record) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key == "delete") {
                  setDeletedId(record.id);
                  setOpenDeleteModal(true);
                }
              }}
            >
              <>
                <Menu.Item key="delete">{words["Delete"]}</Menu.Item>
              </>
            </Menu>
          }
          trigger={["click"]}
        >
          <Button>
            <div className="flex items-center justify-center cursor-pointer text-[#0025F2]">
              <DotMenuIcon />
            </div>
          </Button>
        </Dropdown>
      ),
    },
  ];

  const doDeleteStaff = () => {
    deleteStaff(deletedId);
  };

  return (
    <>
      {staffLoading || (deleteStaffLoading && <AppLoader />)}
      <Layout pageTitle={words["Staff"]}>
        <div className="store-page min-h-screen flex">
          <div className="wrap wrapWidth flex flex-col">
            <div className="flex flex-col gap-5 mt-6">
              <div className="flex items-center justify-between gap-3">
                {/* <div className="flex flex-1">
                <SearchBar />
              </div> */}
                <div className="flex flex-1 justify-end">
                  <button
                    className="!flex items-center button bg-themeColor rounded-full gap-2 !py-[2px]"
                    onClick={(e) => setOpenModal(true)}
                    style={{ zIndex: 1 }}
                  >
                    <div className="flex items-center justify-center text-white font-normal text-2xl">
                      +
                    </div>
                    <label className="text-white cursor-pointer">
                      {words["Add"]}
                    </label>
                  </button>
                </div>
              </div>
              <div style={{ marginTop: -50 }}>
                <DataTable
                  searchContainerStyle={{ width: "40%" }}
                  data={staff?.data ?? []}
                  columns={columns}
                />
              </div>
            </div>
          </div>
          <Modal open={openModal} setOpen={setOpenModal}>
            <AddStaffModel openModal={openModal} setOpenModal={setOpenModal} />
          </Modal>
          <Modal open={openDeleteModal} setOpen={setOpenDeleteModal}>
            <DeleteConfirmationModel
              onSubmit={doDeleteStaff}
              openModal={openDeleteModal}
              setOpenModal={setOpenDeleteModal}
            />
          </Modal>
        </div>
      </Layout>
    </>
  );
};

export default Staff;
