import React, { useEffect } from 'react';
import './index.css';
import Loader from '../Loader';

const AppLoader = () => {
  useEffect(() => {
    // Disable scrolling on mount
    document.body.style.overflow = 'hidden';

    // Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <Loader/>
    // <div
    //   className="loader-overlay">
    //   <div className="loader">
    //     <div className="spinner"></div>
    //   </div>
    // </div>
  );
};

export default AppLoader;
