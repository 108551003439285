import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import SwapVert from '@mui/icons-material/SwapVert';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
    TextField,
    Button,
    Menu,
    MenuItem
} from '@mui/material';
import { useSelector } from 'react-redux';

const DataTable = ({
    draggable,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDrop,
    data = [],
    columns,
    searchColoumns,
    searchContainerStyle,
    isSearch = null,
    sortKey = null,
    sortDirection = null,
    isPagination = true,
}) => {
    const words = useSelector((state) => state.authReducer.words);
    const lang = useSelector((state) => state.authReducer.lang);

    // const classTextAlign = lang === 'ar' ? 'text-right' : 'text-left';
    const classTextAlign = lang === 'ar' ? 'right' : 'left'

    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: sortKey ?? 'id', direction: sortDirection ?? 'descending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage] = useState(10);



    const sortedData = [...data].sort((a, b) => {
        if (sortConfig.key !== null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'ascending' ? 1 : -1;
            }
        }
        return 0;
    });


    const filteredData = sortedData.filter((item) =>
        Object.values(item).some(
            (value) => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );


    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSort = (key, dir) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction: dir ?? direction });
    };





    // Compute the paginated data
    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    useEffect(() => {
        if (sortKey && sortDirection) {
            handleSort(sortKey, sortDirection);
        }
    }, [sortKey, sortDirection]);

    return (
        <div style={{ width: '98%' }}>
            {
                isSearch &&

                <div
                    style={searchContainerStyle}
                    className="flex items-center gap-3 w-full rounded-full border border-[#D1D5DB] px-3 py-2 focus-within:border-themeColor anim"
                >
                    <div className="flex items-center justify-center h-5 w-5">
                        <SearchIcon />
                    </div>
                    <input
                        onChange={handleSearch}
                        type="text"
                        placeholder={words['search']}
                        className="w-full cleanbtn text-base font-normal"
                    />
                </div>
            }
            <TableContainer
                style={{ marginTop: 10, borderRadius: 20 }}
                component={Paper}
            >
                <Table>
                    <TableHead
                        style={{ backgroundColor: '#F5F7FB' }}
                    >
                        <TableRow style={{ textAlign: classTextAlign }}>
                            {columns?.map((column) => (
                                <TableCell
                                    key={column.key}
                                    onClick={() => handleSort(column.dataIndex)}
                                    style={{
                                        padding: '8px 16px',
                                        textAlign: classTextAlign,
                                        fontSize: '14px',
                                        color: 'black',
                                        fontWeight: '500',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            // justifyContent: classTextAlign === 'right' ? 'flex-end' : 'flex-start',
                                        }}
                                    >
                                        {column.title}
                                        <SwapVert style={{ fontSize: 15 }} />
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row, index) => (
                            <TableRow
                                draggable={draggable}
                                onDragStart={(e) => onDragStart(e, row)}
                                onDragEnd={onDragEnd}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => onDrop(e, row)}
                                key={index}
                            >
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.key}
                                        style={{
                                            padding: '8px 16px',
                                            textAlign: classTextAlign,
                                            fontSize: '12px',
                                            color: '#292D32',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TableContainer
                style={{ marginTop: 10, borderRadius: 20 }}
                className={`${classTextAlign}`}
                component={Paper}
            >
                <Table>
                    <TableHead
                        style={{ backgroundColor: '#F5F7FB' }}
                    >
                        <TableRow className={`${classTextAlign}`}>
                            {columns?.map((column) => (
                                <TableCell
                                    className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}
                                    key={column.key}
                                    onClick={() => handleSort(column.dataIndex)}
                                >
                                    <div
                                        className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}
                                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                                    >
                                        {column.title}
                                        <SwapVert style={{ fontSize: 15 }} />
                                    </div>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedData.map((row, index) => (
                            <TableRow
                                draggable={draggable}
                                onDragStart={(e) => onDragStart(e, row)}
                                onDragEnd={onDragEnd}
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => onDrop(e, row)}
                                key={index}
                            >
                                {columns.map((column) => (
                                    <TableCell
                                        style={{ fontSize: 12 }}
                                        className={`px-2 py-2 ${classTextAlign} whitespace-nowrap text-xs text-[#292D32]`}
                                        key={column.key}
                                    >
                                        {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> */}

            {/* Pagination Controls */}

            {
                isPagination &&

                <div className="flex justify-end items-center mt-4 space-x-2">
                    {/* Previous Button */}
                    <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="px-4 py-2 bg-gray-200 text-gray-600 rounded-lg disabled:opacity-50"
                    >
                        &lt; {/* or use an icon */}
                    </button>

                    {/* Page Numbers */}
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{ color: currentPage === index + 1 ? 'white' : 'black' }}
                            className={`px-4 py-2 rounded-lg ${currentPage === index + 1 ? 'bg-themeColor' : 'bg-gray-100'} text-gray-600`}
                        >
                            {index + 1}
                        </button>
                    ))}

                    {/* Next Button */}
                    <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="px-4 py-2 bg-gray-200 text-gray-600 rounded-lg disabled:opacity-50"
                    >
                        &gt; {/* or use an icon */}
                    </button>
                </div>

            }
            {/* <div className="flex justify-between items-center mt-4">
                <Button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </Button>
                <span>
                    Page {currentPage} of {totalPages}
                </span>
                <Button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </Button>
            </div> */}
        </div>
    );
};

export default DataTable;


// import React, { useEffect, useState } from 'react';
// import SearchIcon from "@mui/icons-material/Search";
// import SwapVert from "@mui/icons-material/SwapVert";
// import {
//     Table,
//     TableHead,
//     TableBody,
//     TableRow,
//     TableCell,
//     TableContainer,
//     Paper,
//     TextField,
//     Link,
//     Button,
//     Dropdown,
//     Menu,
//     MoreOutlined,
// } from '@mui/material';
// import { SearchSettingIcon } from '../assets/Icons';
// import { useSelector } from 'react-redux';


// const DataTable = ({
//     draggable,
//     onDragStart,
//     onDragEnd,
//     onDragOver,
//     onDrop,
//     data = [],
//     columns,
//     searchColoumns,
//     searchContainerStyle,
//     sortKey = null,
//     sortDirection = null
// }) => {


//     const words = useSelector((state) => state.authReducer.words);
//     const lang = useSelector((state) => state.authReducer.lang);
//     const classTextAlign = lang == 'ar' ? 'text-right' : 'text-left';

//     const [searchTerm, setSearchTerm] = useState('');
//     const [sortConfig, setSortConfig] = useState({ key: sortKey ?? 'id', direction: sortDirection ?? 'descending' });
//     const [dataTemp, setDataTemp] = useState(data);


//     const handleSearch = (event) => {
//         setSearchTerm(event.target.value);
//         // let filterData = data.filter((item) => searchColoumns.some((coloumn) => item[coloumn].toLowerCase().includes(event.target.value.toLowerCase())));
//         // setDataTemp(filterData);    

//     };

//     const handleSort = (key, dir) => {
//         let direction = 'ascending';
//         if (sortConfig.key === key && sortConfig.direction === 'ascending') {
//             direction = 'descending';
//         }
//         setSortConfig({ key, direction: dir ?? direction });
//     };

//     const sortedData = [...data].sort((a, b) => {
//         if (sortConfig.key !== null) {
//             if (a[sortConfig.key] < b[sortConfig.key]) {
//                 return sortConfig.direction === 'ascending' ? -1 : 1;
//             }
//             if (a[sortConfig.key] > b[sortConfig.key]) {
//                 return sortConfig.direction === 'ascending' ? 1 : -1;
//             }
//         }
//         return 0;
//     });

//     const filteredData = sortedData.filter((item) =>
//         Object.values(item).some(
//             (value) => typeof value === 'string' && value.toLowerCase().includes(searchTerm.toLowerCase())
//         )
//     );

//     useEffect(() => {
//         if (sortKey && sortDirection) {
//             handleSort(sortKey, sortDirection)
//         }
//     }, [])

//     return (
//         <div style={{ width: "98%", }}>

//             <div
//                 style={searchContainerStyle}
//                 className="flex items-center gap-3 w-full rounded-full border border-[#D1D5DB] px-3 py-2 focus-within:border-themeColor anim">
//                 <div className="flex items-center justify-center h-5 w-5">
//                     <SearchIcon />
//                 </div>
//                 <input
//                     onChange={handleSearch}
//                     type="text"
//                     placeholder={words["search"]}
//                     className="w-full cleanbtn text-base font-normal"
//                 />
//                 {/* <div className="flex items-center justify-center h-5 w-5">
//                     <SearchSettingIcon />
//                 </div> */}
//             </div>




//             <TableContainer
//                 style={{ marginTop: 10, borderRadius: 20 }}
//                 className={`${classTextAlign}`} 
//                 component={Paper}>
//                 <Table>
//                     <TableHead
//                         style={{ backgroundColor: '#F5F7FB', }}>
//                         <TableRow className={`${classTextAlign}`} >
//                             {columns?.map((column) => (
//                                 <TableCell
//                                     // style={{ fontFamily: 'Poppins', fontSize: 14, color: 'black' }}
//                                     className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}
//                                     key={column.key} onClick={() => handleSort(column.dataIndex)}>
//                                     <div
//                                         className={`px-2 py-2 ${classTextAlign} text-sm text-black font-medium`}
//                                         style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
//                                         {column.title}
//                                         <SwapVert style={{ fontSize: 15 }} />
//                                     </div>

//                                 </TableCell>
//                             ))}
//                         </TableRow>
//                     </TableHead>
//                     <TableBody>
//                         {filteredData?.map((row, index) => (
//                             <TableRow
//                                 draggable={draggable}
//                                 onDragStart={(e) => onDragStart(e, row)}
//                                 onDragEnd={onDragEnd}
//                                 onDragOver={(e) => {
//                                     e.preventDefault();
//                                 }}
//                                 onDrop={(e) => onDrop(e, row)}
//                                 key={index}>
//                                 {columns.map((column) => (
//                                     <TableCell
//                                         style={{ fontSize: 12, }}
//                                         className={`px-2 py-2 ${classTextAlign} whitespace-nowrap text-xs text-[#292D32]`}
//                                         key={column.key}>
//                                         {column.render ? column.render(row[column.dataIndex], row) : row[column.dataIndex]}
//                                     </TableCell>
//                                 ))}
//                             </TableRow>
//                         ))}
//                     </TableBody>
//                 </Table>
//             </TableContainer>
//         </div>
//     );
// };

// export default DataTable;
