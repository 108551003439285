import React from "react";
import { Backdrop, CircularProgress, Box, Typography } from "@mui/material";

const Loader = ({ open = true, message = "Loading..." }) => {
    return (
        <Backdrop
            open={open}
            sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                color: "#fff",
                backgroundColor: "rgba(0, 0, 0, 0.7)", // Dim background
            }}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <CircularProgress color="inherit" />
                {message && (
                    <Typography
                        variant="body1"
                        sx={{
                            mt: 2,
                            color: "white",
                            fontFamily: "PRe",
                            textAlign: "center",
                        }}
                    >
                        {message}
                    </Typography>
                )}
            </Box>
        </Backdrop>
    );
};

export default Loader;


// import React from "react";
// import Spinner from "react-svg-spinner";
// import TypeWriterEffect from "react-typewriter-effect";
// const Loader = () => {
//   return (
//     <div className="min-h-[300px] flex justify-center items-center">
//       <div className="">
//         <div className="block mb-6">
//           <Spinner color="black" size="100px" thickness={2} />
//         </div>
//         <TypeWriterEffect
//           textStyle={{ fontFamily: "inherit", color: "black" }}
//           startDelay={100}
//           cursorColor="black"
//           text="Loading..."
//           typeSpeed={150}
//           loop={true}
//         />
//       </div>
//     </div>
//   );
// };

// export default Loader;
