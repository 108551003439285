import React, { useState, useEffect } from "react";
import {
  DotMenuIcon,
  TrashIcon,
  EyeIcon2,
  EditIcon3,
} from "../../../assets/Icons";
import { BASE_URL, IMAGE_URL } from "../../../Api/constants";
import { routes } from "../../../Api/routes";
import { postRequest } from "../../../Api";
import { toast } from "react-toastify";
import DataTable from "../../../components/DataTable";
import { capitalizeFirstLetter, getDateString } from "../../../utils/functions";
import AppLoader from "../../../components/Loader/AppLoader";
import Modal from "../../../components/Modal";
import AddPostModel from "../../../components/AddPostModal";
import RejectedReasonModel from "../../../components/RejectedReasonModel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Dropdown, Menu, Button, Typography, Image } from "antd";
import AddBalanceOpenModel from "./addbalancepopup";

const ReferralCodeTable = ({
  loadingState = () => {},
  data,
  id,
  refreshData = () => {},
  loading = () => {},
}) => {
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();
  const isAdmin = JSON.parse(localStorage.getItem("admin"));

  const columns = [
    {
      title: words["Id"],
      dataIndex: "id",
      key: "id",
    },
    {
      title: words["Image"],
      key: "image",
      render: (_, record) => (
        <>
          <div
            className="px-2 py-2 text-left text-sm text-black font-medium"
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Image
              src={IMAGE_URL + record.referred_image}
              style={{ width: 30, height: 30, borderRadius: 5 }}
            />
          </div>
        </>
      ),
    },

    {
      title: words["Name"],
      dataIndex: "referred_username",
      key: "referred_username",
    },

    {
      title: words["Amount added"],
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: words["Date"],
      dataIndex: "Date",
      key: "Date",
      render: (_, record) => (
        <div
          className="px-2 py-2 text-left text-sm text-black font-medium"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {getDateString(record.created_at)}{" "}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        <div>
          <DataTable
            searchContainerStyle={{ width: "40%" }}
            data={data}
            columns={columns}
          />
        </div>
      </div>
    </>
  );
};

export default ReferralCodeTable;
