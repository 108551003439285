import React, { useState, useEffect } from 'react';
import { Layout } from '../../components';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid, Box, Avatar, Button, Stack } from '@mui/material';
import { Image } from 'antd';
import { toast } from 'react-toastify';
import { IMAGE_URL } from '../../Api/constants';
import { useChangeMerchantAuthStatusMutation, useChangeRiderAuthStatusMutation } from '../../store/services/userService';
import Modal from '../../components/Modal';
import RejectedReasonModel from '../../components/RejectedReasonModel';

const UserRequestDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const record = location.state?.record;

    const [rejectedModel, setRejectedModel] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changeMerchantAuthStatus, { isSuccess: merchantSuccess, isError: merchantError }] = useChangeMerchantAuthStatusMutation();
    const [changeRiderAuthStatus, { isSuccess: riderSuccess, isError: riderError }] = useChangeRiderAuthStatusMutation();

    const isMerchant = record?.m_type !== undefined;
    const isCompany = isMerchant && record.m_type === 'Company';

    const handleApprove = () => {
        if (isMerchant) {
            doChangeMerchantStatus('accepted');
        } else {
            doChangeRiderStatus('accepted');
        }
    };

    const handleReject = (reason) => {
        if (isMerchant) {
            doChangeMerchantStatus('rejected', reason);
        } else {
            doChangeRiderStatus('rejected', reason);
        }
    };

    const doChangeMerchantStatus = async (status, reason = null) => {
        setRejectedModel(false);
        setLoading(true);

        const body = { status, id: record.id };
        if (status === "rejected") body.rejected_reason = reason;

        await changeMerchantAuthStatus(body);
    };

    const doChangeRiderStatus = async (status, reason = null) => {
        setRejectedModel(false);
        setLoading(true);

        const body = { status, id: record.id };
        if (status === "rejected") body.rejected_reason = reason;

        await changeRiderAuthStatus(body);
    };

    useEffect(() => {
        if (merchantSuccess || riderSuccess) {
            setLoading(false);
            toast.success('Action completed successfully!');
            navigate('/dashboard/users-requests'); // Navigate to desired screen (e.g., dashboard)
        }

        if (merchantError || riderError) {
            setLoading(false);
            toast.error('An error occurred. Please try again.');
        }
    }, [merchantSuccess, riderSuccess, merchantError, riderError, navigate]);

    return (
        <Layout pageTitle="User Details">
            <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
                <Card elevation={3} sx={{ borderRadius: 4, padding: 2 }}>
                    <CardContent>
                        <Typography
                            variant="h5"
                            gutterBottom
                            sx={{ fontFamily: 'MSBo', textAlign: 'center', mb: 3 }}
                        >
                            {isMerchant ? 'Merchant Details' : 'Rider Details'}
                        </Typography>

                        <Image
                            src={IMAGE_URL + record?.image}
                            alt="Profile"
                            style={{
                                width: "140px",
                                height: "140px",
                                marginTop: 15,
                                borderRadius: 10,
                            }}
                        />

                        <Grid container spacing={3}>
                            {/* Common Details */}
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontFamily: 'MMe' }}><strong>Name:</strong> {record.username}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontFamily: 'MMe' }}><strong>Phone:</strong> {record.phone}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography sx={{ fontFamily: 'MMe' }}><strong>WhatsApp:</strong> {record.whatsapp_no || 'N/A'}</Typography>
                            </Grid>
                            {isMerchant && (
                                <Grid item xs={12} md={6}>
                                    <Typography sx={{ fontFamily: 'MMe' }}><strong>Type:</strong> {record.m_type}</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily: 'MMe' }}><strong>Trade Type:</strong> {record.trade_type || 'N/A'}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography sx={{ fontFamily: 'MMe' }}><strong>Referral Code:</strong> {record.rider_refferal_code || 'N/A'}</Typography>
                            </Grid>

                            {/* Conditional Rendering for Merchant */}
                            {isMerchant && isCompany && (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>Company Name:</strong> {record.company_name}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>Company Place:</strong> {record.company_place}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>CR Number:</strong> {record.cr_number}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}>
                                            <strong>Company CR:</strong>
                                            {record.company_cr ? (
                                                <a
                                                    href={`${IMAGE_URL}${record.company_cr}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    style={{ marginLeft: 8, color: '#1976d2', textDecoration: 'none' }}
                                                >
                                                    View File
                                                </a>
                                            ) : (
                                                ' N/A'
                                            )}
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                            {/* Conditional Rendering for Rider */}
                            {!isMerchant && (
                                <>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>Email:</strong> {record.email || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>ID Card Number:</strong> {record.id_card_no || 'N/A'}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>ID Front:</strong></Typography>
                                        {record.id_front ? (
                                            <Avatar
                                                src={`${IMAGE_URL}${record.id_front}`}
                                                alt="ID Front"
                                                sx={{ width: 100, height: 100, mt: 1 }}
                                            />
                                        ) : (
                                            <Typography sx={{ fontFamily: 'MRe', mt: 1 }}>N/A</Typography>
                                        )}
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography sx={{ fontFamily: 'MMe' }}><strong>ID Back:</strong></Typography>
                                        {record.id_back ? (
                                            <Avatar
                                                src={`${IMAGE_URL}${record.id_back}`}
                                                alt="ID Back"
                                                sx={{ width: 100, height: 100, mt: 1 }}
                                            />
                                        ) : (
                                            <Typography sx={{ fontFamily: 'MRe', mt: 1 }}>N/A</Typography>
                                        )}
                                    </Grid>
                                </>
                            )}
                        </Grid>

                        {/* Approve and Reject Buttons */}
                        <Stack
                            direction="row"
                            justifyContent="center"
                            spacing={2}
                            sx={{ mt: 4 }}
                        >
                            <Button
                                variant="contained"
                                sx={{
                                    fontFamily: 'MMe',
                                    px: 4,
                                    height: 45,
                                    bgcolor: '#2EBFB9',
                                    fontSize: '1rem',
                                    textTransform: 'capitalize',
                                    borderRadius: 10,
                                }}
                                onClick={handleApprove}
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Approve'}
                            </Button>
                            <Button
                                variant="contained"
                                color="error"
                                sx={{
                                    fontFamily: 'MMe',
                                    px: 4,
                                    height: 45,
                                    fontSize: '1rem',
                                    textTransform: 'capitalize',
                                    borderRadius: 10,
                                }}
                                onClick={() => setRejectedModel(true)}
                                disabled={loading}
                            >
                                {loading ? 'Processing...' : 'Reject'}
                            </Button>
                        </Stack>
                    </CardContent>
                </Card>
            </Box>

            <Modal open={rejectedModel} setOpen={setRejectedModel}>
                <RejectedReasonModel
                    openModal={rejectedModel}
                    setOpenModal={setRejectedModel}
                    onSubmit={(reason) => handleReject(reason)}
                />
            </Modal>
        </Layout>
    );
};

export default UserRequestDetails;


// import React, { useState } from 'react';
// import { Layout } from '../../components';
// import { useLocation } from 'react-router-dom';
// import { Card, CardContent, Typography, Grid, Box, Avatar, Button, Stack } from '@mui/material';
// import { Image } from 'antd';
// import { IMAGE_URL } from '../../Api/constants';
// import { useChangeMerchantAuthStatusMutation, useChangeRiderAuthStatusMutation } from '../../store/services/userService';
// import Modal from '../../components/Modal';
// import RejectedReasonModel from '../../components/RejectedReasonModel';

// const UserRequestDetails = () => {
//     const location = useLocation();
//     const record = location.state?.record;

//     const [rejectedModel, setRejectedModel] = useState(false);
//     const [loading, setLoading] = useState(false);
//     const [openModal, setOpenModal] = useState(false);
//     const [changeMerchantAuthStatus, response] = useChangeMerchantAuthStatusMutation();
//     const [changeRiderAuthStatus] = useChangeRiderAuthStatusMutation();



//     const isMerchant = record?.m_type !== undefined;
//     const isCompany = isMerchant && record.m_type === 'Company';

//     const handleApprove = () => {
//         if (isMerchant) {
//             doChangeMerchantStatus('accepted');
//         }
//         else doChangeRiderStatus('accepted')
//     };

//     const handleReject = (reason) => {
//         if (isMerchant) {
//             doChangeMerchantStatus('rejected', reason);
//         }
//         else doChangeRiderStatus('rejected', reason)
//     };



//     const doChangeMerchantStatus = async (status, reason = null) => {
//         setRejectedModel(false);
//         const body = {
//             status,
//             id: record.id,
//         };
//         if (status == "rejected") body.rejected_reason = reason;
//         setLoading(true);
//         changeMerchantAuthStatus(body);
//     };


//     const doChangeRiderStatus = async (status, reason = null) => {

//         setRejectedModel(false);
//         const body = {
//             status,
//             id: record.id,
//         };
//         if (status == "rejected") body.rejected_reason = reason;
//         setLoading(true);
//         changeRiderAuthStatus(body);
//     };




//     return (
//         <Layout pageTitle="User Details">
//             <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4, p: 2 }}>
//                 <Card elevation={3} sx={{ borderRadius: 4, padding: 2 }}>
//                     <CardContent>
//                         <Typography
//                             variant="h5"
//                             gutterBottom
//                             sx={{ fontFamily: 'MSBo', textAlign: 'center', mb: 3 }}
//                         >
//                             {isMerchant ? 'Merchant Details' : 'Rider Details'}
//                         </Typography>

//                         <Image
//                             src={IMAGE_URL + record?.image}
//                             alt="Profile"
//                             style={{
//                                 width: "140px",
//                                 height: "140px",
//                                 marginTop: 15,
//                                 borderRadius: 10,
//                             }}
//                         />

//                         <Grid container spacing={3}>
//                             {/* Common Details */}
//                             <Grid item xs={12} md={6}>
//                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>Name:</strong> {record.username}</Typography>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>Phone:</strong> {record.phone}</Typography>
//                             </Grid>
//                             <Grid item xs={12} md={6}>
//                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>WhatsApp:</strong> {record.whatsapp_no || 'N/A'}</Typography>
//                             </Grid>
//                             {isMerchant && (
//                                 <Grid item xs={12} md={6}>
//                                     <Typography sx={{ fontFamily: 'MMe' }}><strong>Type:</strong> {record.m_type}</Typography>
//                                 </Grid>
//                             )}
//                             <Grid item xs={12}>
//                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>Trade Type:</strong> {record.trade_type || 'N/A'}</Typography>
//                             </Grid>
//                             <Grid item xs={12}>
//                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>Referral Code:</strong> {record.rider_refferal_code || 'N/A'}</Typography>
//                             </Grid>

//                             {/* Conditional Rendering for Merchant */}
//                             {isMerchant && (
//                                 <>
//                                     {isCompany && (
//                                         <>
//                                             <Grid item xs={12} md={6}>
//                                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>Company Name:</strong> {record.company_name}</Typography>
//                                             </Grid>
//                                             <Grid item xs={12} md={6}>
//                                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>Company Place:</strong> {record.company_place}</Typography>
//                                             </Grid>
//                                             <Grid item xs={12} md={6}>
//                                                 <Typography sx={{ fontFamily: 'MMe' }}><strong>CR Number:</strong> {record.cr_number}</Typography>
//                                             </Grid>
//                                             <Grid item xs={12} md={6}>
//                                                 <Typography sx={{ fontFamily: 'MMe' }}>
//                                                     <strong>Company CR:</strong>
//                                                     {record.company_cr ? (
//                                                         <a
//                                                             href={`${IMAGE_URL}${record.company_cr}`}
//                                                             target="_blank"
//                                                             rel="noopener noreferrer"
//                                                             style={{ marginLeft: 8, color: '#1976d2', textDecoration: 'none' }}
//                                                         >
//                                                             View File
//                                                         </a>
//                                                     ) : (
//                                                         ' N/A'
//                                                     )}
//                                                 </Typography>
//                                             </Grid>
//                                         </>
//                                     )}
//                                 </>
//                             )}

//                             {/* Conditional Rendering for Rider */}
//                             {!isMerchant && (
//                                 <>
//                                     <Grid item xs={12} md={6}>
//                                         <Typography sx={{ fontFamily: 'MMe' }}><strong>Email:</strong> {record.email || 'N/A'}</Typography>
//                                     </Grid>
//                                     <Grid item xs={12} md={6}>
//                                         <Typography sx={{ fontFamily: 'MMe' }}><strong>ID Card Number:</strong> {record.id_card_no || 'N/A'}</Typography>
//                                     </Grid>
//                                     <Grid item xs={12} md={6}>
//                                         <Typography sx={{ fontFamily: 'MMe' }}><strong>ID Front:</strong></Typography>
//                                         {record.id_front ? (
//                                             <Avatar
//                                                 src={`${IMAGE_URL}${record.id_front}`}
//                                                 alt="ID Front"
//                                                 sx={{ width: 100, height: 100, mt: 1 }}
//                                             />
//                                         ) : (
//                                             <Typography sx={{ fontFamily: 'MRe', mt: 1 }}>N/A</Typography>
//                                         )}
//                                     </Grid>
//                                     <Grid item xs={12} md={6}>
//                                         <Typography sx={{ fontFamily: 'MMe' }}><strong>ID Back:</strong></Typography>
//                                         {record.id_back ? (
//                                             <Avatar
//                                                 src={`${IMAGE_URL}${record.id_back}`}
//                                                 alt="ID Back"
//                                                 sx={{ width: 100, height: 100, mt: 1 }}
//                                             />
//                                         ) : (
//                                             <Typography sx={{ fontFamily: 'MRe', mt: 1 }}>N/A</Typography>
//                                         )}
//                                     </Grid>
//                                 </>
//                             )}
//                         </Grid>

//                         {/* Approve and Reject Buttons */}
//                         <Stack
//                             direction="row"
//                             justifyContent="center"
//                             spacing={2}
//                             sx={{ mt: 4 }}
//                         >
//                             <Button
//                                 variant="contained"
//                                 sx={{
//                                     fontFamily: 'MMe',
//                                     px: 4,
//                                     height: 45,
//                                     bgcolor: '#2EBFB9',

//                                     fontSize: '1rem',
//                                     textTransform: 'capitalize',
//                                     borderRadius: 10,
//                                 }}
//                                 onClick={handleApprove}
//                             >
//                                 Approve
//                             </Button>
//                             <Button
//                                 variant="contained"
//                                 color="error"
//                                 sx={{
//                                     fontFamily: 'MMe',
//                                     px: 4,
//                                     height: 45,
//                                     fontSize: '1rem',
//                                     textTransform: 'capitalize',
//                                     borderRadius: 10,
//                                 }}
//                                 onClick={()=>setRejectedModel(true)}
//                             >
//                                 Reject
//                             </Button>
//                         </Stack>
//                     </CardContent>
//                 </Card>
//             </Box>

//             <Modal open={rejectedModel} setOpen={setRejectedModel}>
//                 <RejectedReasonModel
//                     openModal={rejectedModel}
//                     setOpenModal={setRejectedModel}
//                     onSubmit={(reason) => handleReject(reason)}
//                 />
//             </Modal>


//         </Layout>
//     );
// };

// export default UserRequestDetails;
