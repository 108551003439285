import React, { useEffect, useState } from "react";
import { CrossIcon } from "../assets/Icons";
import { Checkbox, FormControlLabel, Grid, TextField } from "@mui/material";
import { toast } from "react-toastify";
import { useSendNotificationMutation } from "../store/services/notificationService";
import AppLoader from "./Loader/AppLoader";
import { useSelector } from "react-redux";
import { Description } from "@mui/icons-material";

const NotificationModel = ({ openModal, setOpenModal, users }) => {
  const words = useSelector((state) => state.authReducer.words);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  // const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [sendNotification, { isLoading, isSuccess, isError }] =
    useSendNotificationMutation();

  // const handleUserSelection = (userId) => {
  //   if (selectedUsers.includes(userId)) {
  //     setSelectedUsers(selectedUsers.filter((id) => id !== userId));
  //   } else {
  //     setSelectedUsers([...selectedUsers, userId]);
  //   }
  // };

  const handlePostNotification = () => {
    if (!title) return toast.error("Please enter title");
    if (!description) return toast.error("Please enter description");

    const selectedUsers = users.map((v, i) => {
      return v.id;
    });

    // if (!selectedUsers.length) return toast.error("Please select user");

    const body = {
      title,
      description,
      user_id: selectedUsers,
    };
    sendNotification(body);
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value.toLowerCase());
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm) ||
      user.number.toLowerCase().includes(searchTerm)
  );

  useEffect(() => {
    if (isSuccess) {
      toast.success("Notification send successfully");
      setOpenModal(false);
      setTitle("");
      setDescription("");
      // setSelectedUsers([]);
    }
    if (isError) {
      toast.error("Error while sending notification. Please try again later");
    }
  }, [isSuccess, isError]);

  return (
    <div className="flex flex-col shadow-md-custom border border-[#D8D8D8] p-4">
      {isLoading && <AppLoader />}
      <div className="flex items-center justify-between gap-3 mb-5">
        <h1 className="text-black text-lg font-medium">
          {words["Send Notification"]}
        </h1>
        <div
          className="flex items-center justify-center h-8 w-8 bg-[#EFEFEF] rounded-full p-1 hover:cursor-pointer"
          onClick={() => setOpenModal(false)}
        >
          <CrossIcon />
        </div>
      </div>

      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">
            {words["Title"]}
          </label>
          <input
            type="text"
            placeholder={words["Enter Title"]}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>
        <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">
            {words["Description"]}
          </label>
          <textarea
            placeholder={words["Enter the Description here"]}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="min-h-[110px] max-h-[200px] border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
        </div>

        {/* <div className="flex flex-col gap-2">
          <label className="text-black text-sm font-normal">Select Users</label>
          <input
            type="text"
            placeholder="Search user"
            value={searchTerm}
            onChange={(e) => handleSearchChange(e.target.value)}
            className="border border-[#D9D9D9] py-2 px-2 focus:border-themeColor rounded-lg anim font-normal text-sm"
          />
          <Grid container spacing={2} marginTop={2}>
            {filteredUsers.map((user) => (
              <Grid item xs={12} sm={6} key={user.id}>
                <input
                  type="checkbox"
                  id={`user_${user.id}`}
                  checked={selectedUsers.includes(user.id)}
                  onChange={() => handleUserSelection(user.id)}
                  className="border border-[#D9D9D9] rounded-md focus:ring-themeColor"
                />
                <label
                  htmlFor={`user_${user.id}`}
                  className="text-sm"
                  style={{ marginLeft: 10 }}
                >
                  {user.name} - {user.number}
                </label>
               
              </Grid>
            ))}
          </Grid>
        </div> */}

        <div className="flex items-center justify-center">
          <button
            className="button rounded-full !py-2 !px-10"
            onClick={handlePostNotification}
          >
            {words["Post"]}
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotificationModel;
