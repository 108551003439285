import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/reducers/authReducer";
import {
  BellIcon,
  LangSignIcon,
  NotificationIcon,
  SearchIcon,
} from "../../assets/Icons";
import { capitalizeFirstLetter } from "../../utils/functions";

import { setWords } from "../../store/reducers/authReducer";
import { IMAGE_URL } from "../../Api/constants";

const Header = ({ pageTitle }) => {

  const isAdmin = JSON.parse(localStorage.getItem("admin"));
  const words = useSelector((state) => state.authReducer.words);
  const navigate = useNavigate();

  if (!pageTitle) pageTitle = words["Dashboard"];

  const dispatch = useDispatch();
  const [hide, setHide] = useState(false);
  const [show, setShow] = useState(false);
  const userLogout = () => {
    dispatch(logout("admin-token"));
  };

  useEffect(() => {
    document.addEventListener("click", () => {
      document.body.style.overflow = "inherit";
      // setHide(false);
      setShow(false);
    });
  }, []);



  return (
    <div className="header-camp flex z-50 left-0 top-0 right-0 w-full py-3 bg-white min-h-[90px]">
      <div className="wrapWidth flex items-center gap-5">
        <div className="flex items-center gap-5 w-full">
          <h1 className="text-[#18120F] text-xl min-w-fit font-semibold">
            {pageTitle}
          </h1>
          {/* <div className="flex items-center bg-[#D0934D54]/30 p-2 w-full rounded-full focus-within:border-themeColor border anim">
            <input
              type="text"
              placeholder="Search...."
              className="w-full bg-transparent text-[#13131380]/80 text-sm px-2"
            />
            <div className="flex items-center justify-center h-4 w-4">
              <SearchIcon />
            </div>
          </div> */}
        </div>
        <div className="flex items-center justify-center gap-5">
          <div className="flex relative">
            <div
              className="flex items-center justify-center bg-[#D0934D54]/30 rounded-full p-2 h-10 w-10 cursor-pointer hover:border-themeColor border anim"
              onClick={(e) => {
                e.stopPropagation();
                setShow(!show);
              }}
            >
              <LangSignIcon />
            </div>
            <div
              className={`manueProfile flex flex-col ${show ? "show" : "hide"}`}
            >
              <div
                onClick={() => dispatch(setWords("ar"))}
                className="text-sm font-normal py-1 px-5 cursor-pointer"
              >
                Arabic
              </div>
              <div
                onClick={() => dispatch(setWords("en"))}
                className="text-sm font-normal py-1 px-5 cursor-pointer"
              >
                English
              </div>
            </div>
          </div>
          {/* <div className="flex items-center justify-center bg-[#D0934D54]/30 rounded-full p-2 h-10 w-10 cursor-pointer hover:border-themeColor border anim">
            <NotificationIcon />
          </div> */}
          <div
            onClick={() => navigate('/dashboard/profile')}
            className="flex items-center gap-3 hover:cursor-pointer">
            <div className="h-10 w-10 rounded-full">
              <img
                src={IMAGE_URL + "/" + isAdmin?.image}
                // "/images/profile.png"
                // alt="profile"
                className="h-full w-full rounded-full object-cover"
              />
            </div>
            <div className="flex flex-col">
              <h1 className="w-max text-sm font-medium text-[#18120F]">
                {isAdmin?.email}
              </h1>
              <p className="text-xs text-[#6B6B6B]">
                {capitalizeFirstLetter(isAdmin?.type)}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
